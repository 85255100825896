
<mat-dialog-content class="mat-typography">
  <h3 class="dialog-title">{{data.dialogTitle}}</h3>
  <p class="dialog-text">{{data.dialogText}}</p>
  <div class="action-btn-wrapper" fxLayout="row" fxLayoutAlign="space-between center">

<!--
      <div class="left-area" fxFlex="1 1 50">
        <button mat-flat-button disableRipple [mat-dialog-close]="false">{{data.declineBtnText}}</button>
      </div>
      <div class="right-area" fxFlex="1 1 50">
        <button mat-flat-button disableRipple [mat-dialog-close]="true">{{data.confirmBtntext}}</button>
      </div> -->

      <button class="back-btn" [mat-dialog-close]="false" >
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="web-btn-text">{{data.declineBtnText}}</span>
      </button>
      <button class="confirm-btn" [mat-dialog-close]="true">
        <span>{{data.confirmBtntext}}</span>
      </button>

      <!-- THIS IS DONE FOR PROPER ALIGNMENT IN A FAST WAY -->
      <button class="back-btn" [hidden]="true"  style="visibility: hidden;">
        <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span  class="web-btn-text">Back</span>
      </button>
  </div>
</mat-dialog-content>
