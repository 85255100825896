<h2 mat-dialog-title class="web-dialog-title">{{heading}}</h2>

<mat-dialog-content *ngIf="!isInputHidden">
  <div class="collection-input-wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
    <input #inputTitle [(ngModel)]="inputValue" fxFlex="1 1 100" class="collection-input"
     placeholder="{{inputPlaceHolder}}" type="search" #searchInput (keyup.enter)="onMainBtnClick()"/>
  </div>
  <!-- userDataService.getNewCollectionDefaultName() -->
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button class="back-btn" mat-dialog-close  >
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span  class="web-btn-text">Back</span>
  </button>
  <button class="create-btn" (click)="onMainBtnClick()">
    <span>{{mainBtnTitle}}</span>
  </button>

  <!-- THIS IS DONE FOR PROPER ALIGNMENT IN A FAST WAY -->
  <button class="back-btn" [hidden]="true"  style="visibility: hidden;">
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9823 1.5L0.833008 10L10.9823 18.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span  class="web-btn-text">Back</span>
  </button>
</mat-dialog-actions>
