import { UploadTask } from 'firebase/storage';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Share } from '@capacitor/share';
import { SettingsService } from './settings.service';
import { ALLOWED_MODELS_EXTENSIONS, LOG_LEVEL_OPTION } from 'src/app/models/common';
import { RollbarService, CustomRollbar } from './rollbarlog.service';
import { FirebaseModelDto, ICard } from 'src/app/models/card';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { ComponentType } from '@angular/cdk/overlay';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { ICardCollection } from 'src/app/models/collection';
import { environment } from 'src/environments/environment';
import { iconNotificationPopupData, PopupIconNotificationComponent } from '../components/popup-icon-notification/popup-icon-notification.component';

@Injectable({
  providedIn: 'root'
})
export class UtilityFunctionsService {

  public collectionDetailsMock: ICardCollection = {
    collectionId: undefined,
    collectionTitle: 'basic',
    collectionCards: [],
    accessType: 'Private',
    showFullscreenBackgroundPreview: false,
    preloadSettings: {
      enablePreload: true,
      numberOfCardsToPreload: 9,
    }
  }

  lastSnackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;

  constructor(public settingService: SettingsService,private _snackBar: MatSnackBar,public dialog: MatDialog,
    @Inject(RollbarService) private rollbar: CustomRollbar,
    ) { }


  triggerHaptics(): void {
    if(this.settingService.devicePlatform === 'ios') {
      Haptics.impact({
        style: ImpactStyle.Light
      });
    }
  }

  async showShareDialog(text: string): Promise<void> {
    await Share.share({
      title: `Collaboration invite: ${text}`,
      text: `You've been invited for collaboration on ${text}`,
      url: 'http://3dway.io/',
      dialogTitle: `Collaboration invite 3DWay, ${text}`,
    });
  }

  public showSnackBar(text: string, timeout: number): void {
    if(this.settingService.logLevelSelector === LOG_LEVEL_OPTION.DEFAULT) {

      setTimeout(() => {
        const snackBarRef = this._snackBar.open(text, undefined, {
          horizontalPosition: 'start',
          verticalPosition: 'bottom',
          duration: 3000,
          panelClass: 'like-snackbar'
        });

        snackBarRef.afterOpened().subscribe(() => {
          // Запомним элемент Snackbar в переменную
          const snackElement = document.querySelector('.like-snackbar');

          // Добавим обработчик клика на элемент Snackbar
          if (snackElement) {
            snackElement.addEventListener('click', () => {
              snackBarRef.dismiss();
            });
          }
        });
      }, timeout);

    }
    if(this.settingService.logLevelSelector === LOG_LEVEL_OPTION.ERROR_ONLY || this.settingService.logLevelSelector === LOG_LEVEL_OPTION.PROD_MODE
      ) {
      console.log(text);
    }
  }

  public dissmissAllSnackBars(): void {
    console.log('dismissAllSnackBars')
    if(this.lastSnackBarRef) this.lastSnackBarRef.dismiss();
  }

  public showImportantSnackBar(text: string, timeout: number, duration: number = 3000, customClass?: string): void {
    this.rollbar.info(text);
    setTimeout(() => {
      this.lastSnackBarRef = this._snackBar.open(text, undefined, {
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        duration,
        panelClass: ['like-snackbar', customClass].filter(Boolean)
      });

      this.lastSnackBarRef.afterOpened().subscribe(() => {
        // Запомним элемент Snackbar в переменную
        const snackElement = document.querySelector('.like-snackbar');

        // Добавим обработчик клика на элемент Snackbar
        if (snackElement) {
          snackElement.addEventListener('click', () => {
            this.lastSnackBarRef.dismiss();
          });
        }
      });
    }, timeout);
  }

  public getCurrentDateWithNanoseconds() {
    const now = new Date();

    // Get the standard ISO string without the 'Z' at the end: "2023-08-08T09:57:46.566"
    const isoString = now.toISOString().slice(0, -1);

    // Generate a random number to simulate nanoseconds (from 000000000 to 999999999)
    const nanoSeconds = Math.floor(Math.random() * 1000000000).toString().padStart(9, '0');

    return `${isoString}${nanoSeconds}Z`;
  }

  public showErrorSnackBar(text: string, timeout: number, customDuration: number = 3000): void {
    if(this.settingService.logLevelSelector !== LOG_LEVEL_OPTION.PROD_MODE) {
      setTimeout(() => {
        this._snackBar.open(text,undefined,{
          horizontalPosition: 'start',
          verticalPosition: 'bottom',
          duration: customDuration,
          panelClass: 'like-snackbar'
        })
      }, timeout);
    } else {
      const textWithUid = `${text} , uid: ${this.settingService.getOriginalUidFromLocalstorage()}`;
      console.error(textWithUid);
      this.rollbar.error(textWithUid);
    }
  }

  public showImportantErrorSnackBar(text: string, timeout: number, customDuration: number = 3000): void {
    setTimeout(() => {
      this._snackBar.open(text,undefined,{
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
        duration: customDuration,
        panelClass: 'like-snackbar'
      })
    }, timeout);
  }

  public showIconPopupNotification(iconName?: string, text?: string): void {
    const dialogConfig: { data: iconNotificationPopupData } = {
      data: {
        matIconName: iconName || 'checkbox-icon',
        notificationText: text || 'Copied to clipboard'
      }
    };
    this.dialog.open(PopupIconNotificationComponent, dialogConfig);
  }

  public generateHref(): string {
    return `mailto:support@3dway.io`
  }

  public logContactUsClick(): void {
    if(environment.type === 'web') {
      navigator.clipboard.writeText('support@3dway.io').then(() => {
        this.showImportantSnackBar('3DWay support email address copied to clipboard!', 100, 1000);
      })
    }
    this.rollbarInfo(`User have clicked contact us button: ${this.settingService.getOriginalUidFromLocalstorage()}`);
  }

  public rollbarInfo(text: string): void {
    this.rollbar.info(text);
  }

  public rollbarError(text: string): void {
    this.rollbar.error(text);
  }

//   {
//     "cardAuthor": "enJPh5bm30RYp4FTVG4EYReIpub2",
//     "cardCategory": "undefined",
//     "cardContentMobile": "undefined",
//     "cardDescription": "The mecha is co-piloted by two different players, the first serving as a pilot, and the second serving as a gunner.",
//     "cardPosterMobile": "",
//     "cardPrice": 0,
//     "cardTitle": "testxyz1705318534448",
//     "cardType": "model",
//     "id": "testxyz1705318534448",
//     "loadProgress": "0",
//     "loadStatus": "modelDownload",
//     "modifiedDate": "Mon Jan 15 2024 15:35:43 GMT+0400 (Georgia Standard Time)",
//     "purchaseStatus": "uploaded",
//     "warning": ""
// }

  // THIS FORMAT SHOULD BE SYNCHRONIZED WITH YAHOR !!!!!
  public convertFirebaseDtoToGroupedCard(source: FirebaseModelDto): IGroupedCard {
    return {
      id: source.model ? source.model : source.id,
      linkedCollectionId: source.parentCollectionId === 'undefined' ? undefined : source.parentCollectionId,
      previewImageMobile: this.checkUndefinedNullEmpty(source.cardPosterMobile),
      previewVideoMobile: this.checkUndefinedNullEmpty(source.cardContentMobile),
      previewImageWeb: this.checkUndefinedNullEmpty(source.cardPosterWeb),
      previewVideoWeb: this.checkUndefinedNullEmpty(source.cardContentWeb),
      title: source.model ? source.model : source.id,
      fileName: source.model ? source.model : source.id,
      details: source.loadStatus,
      uploadStatus: {
        status: UPLOAD_STATUS.INPROGRESS,
        progress: source.loadProgress,
        spinnerMode: 'determinate',
        progressMsg: '',
        uploadTaskLink: undefined,
        progressStats: ''
      }
    }
  }

  private checkUndefinedNullEmpty(value: string | undefined | null): string {
    if(value === undefined || value === null || value === '' || value === 'undefined') {
      return undefined;
    }
    return value
  }

  public generateMockedUploadItem(lastFileName: string, uploadTask?: UploadTask ): IGroupedCard {
    return {
        id: lastFileName,
        previewImageMobile: undefined,
        previewVideoMobile: undefined,
        previewImageWeb: undefined,
        previewVideoWeb: undefined,
        title: lastFileName,
        fileName: lastFileName,
        details: 'Private · Uploading... ',
        uploadStatus: {
          status: UPLOAD_STATUS.INPROGRESS,
          progress: 0,
          spinnerMode: 'determinate',
          progressMsg: 'Private · Uploading... ',
          progressStats: '',
          uploadTaskLink: uploadTask,
        }
    }
  }

  public createEmptyCollection(collectionName: string): ICardCollection {

    const tempId = window.btoa(Math.random().toString().slice(0,6));
    const emptyCollection: ICardCollection = {
      collectionId: tempId,
      collectionTitle: collectionName,
      collectionCards: [],
      accessType: 'Private',
    }
    return emptyCollection;
  }

  public uploadServiceNotification(text: string, duration: number, buttonOktext?: string): MatSnackBarRef<TextOnlySnackBar> {
    return this._snackBar.open(`${text}`, buttonOktext, {
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      duration
    });
  }

  public uploadServiceNotificationFromComponent<T>(text: string, duration: number, buttonOktext: string, component: ComponentType<T>,): MatSnackBarRef<T> {
    return this._snackBar.openFromComponent(component, {
      data: { timeLeft: 4 },
      duration: 4000,
      panelClass: 'custom-snackbar'
    });
  }
  public async pickFile(): Promise<File | null> {
    try {
      const result = await FilePicker.pickFiles({
        multiple: false,
        types: ['model/vnd.pixar.usdz', 'model/vnd.usdz+zip'],
        readData: true,
      });

      if (result && result.files && result.files.length > 0) {
        const file = result.files[0];
        return this.createWebAPIFile(file);
      }
    } catch (error) {
      console.error('Error picking file:', error);
    }
    return null;
  }

  private base64Decode(data: string): Uint8Array | null {
    try {
      const byteString = atob(data);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }

      return intArray;
    } catch (error) {
      console.error('Error decoding Base64:', error);
      return null;
    }
  }

  public createWebAPIFile(file: any): globalThis.File | null {
    if (!file.data) {
      console.error('File data is not available');
      return null;
    }

    const intArray = this.base64Decode(file.data);

    if (!intArray) {
      console.error('Failed to decode Base64 data');
      return null;
    }

    return new globalThis.File([intArray], file.name, { type: file.mimeType });
  }

  modifyFileNameAndPath(fileName: string, pathPrefix: string): string {
    const fileParts = fileName.split('.');
    const fileExtension = '.' + fileParts.pop();

    if (ALLOWED_MODELS_EXTENSIONS.includes(fileExtension)) {
      const filePostfix = new Date().getTime().toString();
      const newName = fileParts.join('.').replace(/\s+/g, '') + filePostfix + fileExtension;
      return pathPrefix + `/` + newName;
    }
    return pathPrefix + `/` + fileName;
  }


  // Keep it here just in case for debug use somewhere in future

  // private generateLongCollectionFeed(sourceFeed: ICardCollection[]): ICardCollection[] {
  //   if(this.settingsService.feedMultiplayerValue === undefined || isNaN(this.settingsService.feedMultiplayerValue) || this.settingsService.feedMultiplayerValue <= 0 ) {
  //     console.error('Something wrong with _feedMultiplayerValue_: ', this.settingsService.feedMultiplayerValue, ' , return default feed')
  //     return sourceFeed
  //   }
  //   const longFeed = [];
  //   sourceFeed.forEach((el)=>{
  //     longFeed.push(el);
  //   })

  //   for (let index = 0; index < this.settingsService.feedMultiplayerValue - 1; index++) {
  //     sourceFeed.forEach(arrEl => {
  //       const synteticCard: ICardCollection = JSON.parse(JSON.stringify(arrEl));
  //       synteticCard.collectionTitle = arrEl.collectionTitle + ` Clone ${index}`;
  //       synteticCard.collectionContentMobile = arrEl.collectionContentMobile + `?=${index}`;
  //       longFeed.push(synteticCard);
  //     });
  //   }
  //   return longFeed
  // }

   public notImplemented(custom: string = ''): void {
     if(custom) {
      this.showSnackBar(custom, 0);
     } else {
      this.showSnackBar('Feature not implemented yet, we are working on it !', 0);
     }
  }

  // private setMockCollectionCardsForEmptyCols(col: ICardCollection[]): ICardCollection[] {
  //   col.forEach(collection => {
  //     if(collection?.collectionCards.length === 0) {
  //       collection.collectionCards = JSON.parse(JSON.stringify(this.mockDataService.modelCardsMockSourceShort));
  //       const leng = Math.floor(Math.random() * (9 - 1) + 1);
  //       collection.collectionCards.length = leng;
  //     }
  //   });
  //   return col
  // }

  public setPropsForCollectionDetailsBasedOnCard(card: ICard, collection?: ICardCollection): ICardCollection {
    const resultCollection = [card].map((cardItem) => {
      return {
        collectionId: cardItem.id, // because it's collection ! (not card)
        collectionTitle: cardItem.cardTitle,
        collectionDescription: cardItem.cardDescription,
        collectionPosterMobile: cardItem.cardPosterMobile,
        collectionContentMobile: cardItem.cardContentMobile,
        collectionAuthor: cardItem.cardAuthor,
        collectionCards: collection?.collectionCards ? collection.collectionCards : [],
        showFullscreenBackgroundPreview: false,
        preloadSettings: collection?.preloadSettings ? collection.preloadSettings : undefined
      }
    })
    return resultCollection[0]
  }

  public generateMockCollection(): ICardCollection {
    return {
      collectionId: window.btoa(Math.random().toString().slice(0,6)),
      collectionTitle: '',
      collectionDescription: '',
      collectionPosterMobile: undefined,
      collectionContentMobile: undefined,
      collectionAuthor: undefined,
      collectionCards: [],
      showFullscreenBackgroundPreview: false,
      preloadSettings: undefined
    }
  }


  calculateIframeDimensions(): { iframeWidth: string; iframeHeight: string } {
    let iframeHeight: string;
    let iframeWidth: string;
    const maxWidth = 1660;
    const maxViewportWidth = window.innerWidth * 0.8;
    const viewportWidth = Math.min(window.innerWidth - 20, maxWidth, maxViewportWidth);
    const maxViewportHeight = window.innerHeight * 0.8;
    // const aspectRatio = 16 / 8.28;
    const aspectRatio = 16 / 9;

    if (maxViewportHeight * aspectRatio <= viewportWidth) {
      iframeHeight = maxViewportHeight + 'px';
      iframeWidth = (maxViewportHeight * aspectRatio) + 'px';
    } else {
      iframeWidth = viewportWidth + 'px';
      iframeHeight = (viewportWidth / aspectRatio) + 'px';
    }
    console.log('this.iframeWidth ', iframeWidth, 'this.iframeHeight ', iframeHeight)
    return { iframeWidth, iframeHeight }
  }

  public removeTimestampFromString(fileContent: string): string {
    const timestampLength = 13;

    if (fileContent.length < timestampLength) {
        console.error('Insufficient file length to cut timestamp.');
        return fileContent;
    }

    const potentialTimestamp = fileContent.slice(-timestampLength);

    if (!/^\d+$/.test(potentialTimestamp)) {
        console.error('Timestamp is not valid (contains non-numeric characters).');
        return fileContent;
    }

    return fileContent.slice(0, -timestampLength);
  }


}
