// mobile-dialog.service.ts
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from './dialog.service';
import { IGroupedCard } from 'src/app/models/groupedCard';
import { ConfirmationDialogComponent } from '../components/dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileDialogService extends DialogService {
  constructor(private dialog: MatDialog) {
    super();
  }

  openUploadErrorDialog(card: IGroupedCard): Observable<boolean> {
    const dialogConfig = {
      data: {
        dialogTitle: '',
        dialogText: `Model "${card.title}”. ${card.details}, please contact support`,
        confirmBtntext: 'Mark as Read',
        declineBtnText: 'Back'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    return dialogRef.afterClosed();
  }
}
