// web-dialog.service.ts
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from './dialog.service';
import { IGroupedCard } from 'src/app/models/groupedCard';
import { WebpopupdialogComponent } from 'src/app/web/webpopupdialog/webpopupdialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebDialogService extends DialogService {
  constructor(private dialog: MatDialog) {
    super();
  }

  openUploadErrorDialog(card: IGroupedCard): Observable<boolean> {
    const dialogConfig = {
      data: {
        heading: `Model “${card.title}”. ${card.details}, please contact support`,
        mainBtnTitle: 'Mark as Read',
        inputValue: '',
        isInputHidden: true
      },
      restoreFocus: false,
      panelClass: 'webpopup-overlay'
    };

    const dialogRef = this.dialog.open(WebpopupdialogComponent, dialogConfig);

    return dialogRef.afterClosed();
  }
}
