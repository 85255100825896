import { WebNavService } from './../webnavigation.service';
import { Subscription } from 'rxjs';
import { ModelsCardComponent } from 'src/app/shared/components/models-card/models-card.component';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
// eslint-disable-next-line max-len
import { CARDS_MODE, CARD_CONTROL_BTN, CARD_PURCHASE_STATUS, ICard, ICardAction, INavpanelAction, LIBARY_MODE, ModelCardRenderedSelection, NAVPANEL_ACTION_TYPE, SUBSCRIPTION_STATUS, SWITCH_PANEL_ITEM, GALLERY_MODE } from '../../models/card';
import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { pageFadingAnimation, fullscreenCardAnimation, cardFeedAnimation, cardFeedNavbarAnimation, inOutAnimation, fullscreenPanelAnimation, modelFeedAnimation, navpanelAnimation } from 'src/app/shared/providers/animations-list';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { ModelCardRendered } from 'src/app/models/card';
import { ICardCollection } from 'src/app/models/collection';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { IGroupedCard, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { environment } from 'src/environments/environment';
import { WebDialogData, WebpopupdialogComponent } from '../webpopupdialog/webpopupdialog.component';
import { Location } from '@angular/common';


@Component({
  selector: 'app-webportfolio',
  templateUrl: './webportfolio.component.html',
  styleUrls: ['./webportfolio.component.scss'],
  animations: [
    pageFadingAnimation,
    fullscreenCardAnimation,
    fullscreenPanelAnimation,
    cardFeedAnimation,
    cardFeedNavbarAnimation,
    navpanelAnimation,
    modelFeedAnimation,
    inOutAnimation
],
})
export class WebPortfolioComponent implements OnInit, OnDestroy {

  @ViewChild('modelCardsFeed')
  public cardFeedRef: ModelsCardComponent;

  LIBARY_MODE = LIBARY_MODE;
  FOOTER_MODE = FOOTER_MODE;
  SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM;
  CARDS_MODE = CARDS_MODE;
  GALLERY_MODE = GALLERY_MODE;

  public cardFeedMode: CARDS_MODE = CARDS_MODE.MODEL_CARD_FEED;

  private routeUid: string;

  public portfolioNavpanelItems: INavpanelAction[];

  public filteredAllModels: ICard[] = [];
  public cardSelectData: ModelCardRenderedSelection[];

  public cardFeedCardStartPosition: number;
  public modelsToShowInFeed: ModelCardRendered[];
  public cardToEdit: ICard;
  public isModelFeedShown: boolean = false;

  public filterCardMode: NAVPANEL_ACTION_TYPE;
  public isNavpanelShown: boolean;
  public navpanelAnimationState: string;

  public isNameChangeDialogueShown: boolean;

  public lastNameInputValue: string;
  public nameDialogDefaultValue: string;

  public isSubscriptionSplashShown: boolean;
  public uploadErrorSub: Subscription;
  public uploadSub: Subscription;
  public uploadFinishedSub: Subscription;
  public webpanelSwitchPanelChangeSubscription: Subscription;
  routeUidSub: Subscription;


  constructor(
    private readonly router: Router,
    private location: Location,
    private readonly route: ActivatedRoute, // <-- добавили
    public cardService: CardsService, public uploadService: UploadFileService,
    public userDataService: UserDataService, public utilityService: UtilityFunctionsService,
    public firebaseAuth: FirebaseAuthService,
    public webNavService: WebNavService,
    public dialog: MatDialog,
    ) {

      this.uploadSub = this.userDataService.uploadedCardRecieved.subscribe((uploadingCard) => {
        // this.filteredAllModels = this.userDataService.allUserCards;
          this.filteredAllModels = this.userDataService.allUserCards;
          if (this.routeUid) {
            this.openModelById(this.routeUid);
          }
      });

      this.uploadFinishedSub = this.uploadService.latestUploadedModelSubscription.subscribe((uploadingCard) => {
        setTimeout(() => {
        this.filteredAllModels = this.userDataService.allUserCards;
        }, 50);
      });
    }

  ngOnInit() {

    // setTimeout(() => {
    //   this.onAllModelsCardClicked(this.userDataService.allUserCards[2]);
    //   const videoSelector = 'app-card-video video'; // Adjust this if you have a more specific way to identify the video.
    //   const videoElement: HTMLVideoElement = document.querySelector(videoSelector);

    //   if (videoElement) {
    //     videoElement.pause();
    //     console.log('Video paused');
    //   } else {
    //     console.error('Video element not found');
    //   }
    // }, 2000);

    this.webNavService.footerMode = FOOTER_MODE.PORTFOLIO;
    this.webNavService.currentWebSwitchPanelMode = SWITCH_PANEL_ITEM.ALL;

    // TEMP
    this.filteredAllModels = this.userDataService.allUserCards;

    this.route.queryParamMap.subscribe(queryParams => {
      const uid = queryParams.get('modelId');
      console.log('modelId:', uid);
      if (uid) {
        this.routeUid = uid;
        this.openModelById(this.routeUid);
      }
    });


    // Dirty workaround to update saved cards when webportfolio is loaded in browser as a first page;
    setTimeout(() => {
      this.updateFilteredModelsState();
    }, 2500);

    this.uploadErrorSub = this.uploadService.uploadErrorSubscription.subscribe(()=> {
      this.handleNavpanelAction({ actionName: 'Upload Model usdz', actionType: NAVPANEL_ACTION_TYPE.TRIAL_EXPIRED });
    })

    this.webpanelSwitchPanelChangeSubscription = this.webNavService.webNavSwitchPanelChange.subscribe((switchPanelItem) => {
        this.handlePanelSwitchChange(switchPanelItem);
    });
  }

  private openModelById(modelId: string) {
    const foundCard = this.filteredAllModels.find(card => card.id === modelId);
    if (foundCard) {
      this.onAllModelsCardClicked(foundCard);
    } else {
      console.log('No card found with UID:', modelId);
    }
  }

  public onNameDialogValueChanged(value: string): void {
    this.lastNameInputValue = value;
    return
  }

  public filterUploadCards(groupedCard: IGroupedCard) {
    if(groupedCard.linkedCollectionId) return false;
    return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING || groupedCard.uploadStatus.status === UPLOAD_STATUS.ERROR;
  }

  public onModelCardPressed() {
    this.webNavService.footerMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    this.webNavService.footerMode = this.webNavService.footerMode === FOOTER_MODE.DEFAULT || this.webNavService.footerMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onAllModelsCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.filteredAllModels);
  }

  public onPurchasedModelsCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.userDataService.userPurchasedCards);
  }

  public onLikedCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.userDataService.savedCards);
  }

  public onUploadedCardClicked(card: ICard): void {
    this.handleCardClick(card, CARDS_MODE.COMPOSITE_FEED, this.userDataService.uploadedCards);
  }

  public onHideNavpanelEventEmitted() {
    this.isNavpanelShown = false;
  }

  private handleCardClick(card: ICard, cardFeedMode: CARDS_MODE, cardArray: ICard[]): void {
    console.log('handleCardClick', card)
    this.utilityService.triggerHaptics();
    this.cardFeedCardStartPosition = cardArray.findIndex(thumb => thumb.cardPosterMobile === card.cardPosterMobile);
    this.modelsToShowInFeed = cardArray;
    this.cardFeedMode = cardFeedMode;
    this.isModelFeedShown = true;
    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
    this.router.navigate([], {
      queryParams: { modelId: card.id },
      queryParamsHandling: 'merge',
      replaceUrl: true
    }).catch(err => {
      console.error('Error updating modelId parameter:', err);
    });

  }

  public updateFilteredModelsState(): void {
    this.handlePanelSwitchChange(this.webNavService.currentWebSwitchPanelMode);
  }

  public handlePanelSwitchChange(button: SWITCH_PANEL_ITEM): void {
    switch (button) {
      case SWITCH_PANEL_ITEM.UPLOADS:
        this.filteredAllModels = this.userDataService.uploadedCards;
        break;
      case SWITCH_PANEL_ITEM.LIKES:
        this.filteredAllModels = this.userDataService.getSavedCards();
        break;
      case SWITCH_PANEL_ITEM.PURCHASES:
        this.filteredAllModels = this.userDataService.userPurchasedCards;
        break;
      case SWITCH_PANEL_ITEM.ALL:
        this.filteredAllModels = this.userDataService.allUserCards;
        break;
      default:
        break;
    }
  }


  public onWebLibraryNavbarBtnClicked(navbarBtn: NAVBAR_BTN): void {
    console.log('Portofolio handler onNavbarBtnClick: ', navbarBtn);
    switch (true) {
      case navbarBtn === NAVBAR_BTN.UPLOAD:
      // UPLOAD_MODEL FOR SUBSCRIBED;
      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER) {
        console.log('PROCEED TO UPLOAD!');
        this.utilityService.showErrorSnackBar('This feature is only available on iOS devices.', 750)
        break;
      }

      // UPLOAD_MODEL_LIMIT_REACHED
      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL_EXPIRED) {
        this.isSubscriptionSplashShown = true;
        this.webNavService.footerMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      }

      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.EXPIRED) {
        this.isSubscriptionSplashShown = true;
        this.webNavService.footerMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      }

      // UPLOAD_MODEL_NOT_SUBSCRIBED
      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.GUEST) {
        this.isSubscriptionSplashShown = true;
        this.webNavService.footerMode = FOOTER_MODE.SUBSCRIPTION_SPLASH_SCREEN;
        break;
      }

      // UPLOAD_MODEL_FREE_TRIAL;
      if (this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.TRIAL) {
        console.log('FREE TRIAL UPLOAD MODE');
        console.log('PROCEED TO UPLOAD!');
        this.utilityService.showErrorSnackBar('This feature is only available on iOS devices.', 750)

        break;
      }
      this.utilityService.rollbarError(`Sorry, seems we having troubles with your subscription status; Please, contact support ${this.userDataService.userSubscriptionStatus}`)
      this.utilityService.showErrorSnackBar('Sorry, seems we having troubles with your subscription status; Please, contact support', 750);
        break;

      case navbarBtn === NAVBAR_BTN.FREE_TRIAL:
        this.utilityService.triggerHaptics();
        this.userDataService.tryForFreeClicked();
        this.isSubscriptionSplashShown = false;
        this.webNavService.footerMode = FOOTER_MODE.PORTFOLIO;
        this.utilityService.showImportantSnackBar('3 models free, subscribe after to store your entire portfolio', 150);

        // NOT IMPLEMENTED
            this.uploadService.pickAndUploadFileNative();

        break;
      case navbarBtn === NAVBAR_BTN.SUBSCRIBE:
        this.utilityService.showImportantErrorSnackBar('Subscribe have not available in web version yet, please use our ios version', 500);
        break;
      case navbarBtn === NAVBAR_BTN.PUBLISH:
        this.userDataService.sendModelForReview(this.cardToEdit);
        this.webNavService.footerMode = FOOTER_MODE.PORTFOLIO;
        this.isModelFeedShown = false;
        this.utilityService.showSnackBar('Model successfuly sent to review', 0);
        // this.webNavService.onWebNavSwitchPanelClick(SWITCH_PANEL_ITEM.UPLOADS);
        break;
      case navbarBtn === NAVBAR_BTN.BUY:
          console.log('BUY BTN CLICKED');
          this.utilityService.showImportantSnackBar('Buy have not available in web version yet, sorry for inconvenience', 500);
        break;
      case navbarBtn === NAVBAR_BTN.BACK_PROFILE:
        this.webNavService.footerMode = FOOTER_MODE.PORTFOLIO;
        break;

      case navbarBtn === NAVBAR_BTN.BACK && this.isNameChangeDialogueShown:
        this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
        this.isNameChangeDialogueShown = false
        break;

      case navbarBtn === NAVBAR_BTN.BACK :
        this.router.navigateByUrl('/');
        break;
      default:
        console.error('Portofolio handler onNavbarBtnClick: ', navbarBtn, ' not handled properly!!!');
        this.router.navigateByUrl('/');
        break;
    }
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
    this.isNavpanelShown = false;
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.SETTINGS:
        this.router.navigateByUrl('/service');
        break;
      case actionName === NAVPANEL_ACTION_TYPE.CONTACT_US:
        this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.LINK_PAYMENTS:
        this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.DELETE_CARD:
        this.openDeleteCardConfirmationDialog(this.cardToEdit);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        this.cardFeedRef.dispatchCurrentCardLikeClick()
        // this.userDataService.removeFromSavedCards(this.cardToEdit);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS:
        this.setFilteringMode(NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS)
        break;
      case actionName === NAVPANEL_ACTION_TYPE.CONTACT_US:
        this.utilityService.notImplemented();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.CANCEL_PUBLISHING:
        this.openCancelPublishingCardConfirmationDialog(this.cardToEdit)
        break;
      default:
        break;
    }
  }

  public onCardControlBtnClicked(action: ICardAction): void {
    this.utilityService.triggerHaptics();
    this.cardToEdit = action.card;
    console.log('card clicked', action);

    switch (action.controlAction) {
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
        this.portfolioNavpanelItems = [
          {actionName: 'Add to Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
        ]
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.RENAME_CARD:
        this.openRenameDialog();

        // this.nameDialogDefaultValue = this.cardToEdit.cardTitle;
        // this.lastNameInputValue = this.cardToEdit.cardTitle;
        // this.webNavService.footerMode = FOOTER_MODE.RENAME_ITEM;
        // this.isNameChangeDialogueShown = true;
        break;
      case CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD:
        this.cardFeedRef.dispatchCurrentCardLikeClick();
        break;
      case CARD_CONTROL_BTN.DELETE_MODEL:
        this.openDeleteCardConfirmationDialog(this.cardToEdit);
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_REJECTED:
        this.utilityService.notImplemented();
        break;
      case CARD_CONTROL_BTN.PUBLISH_SINGLE_CARD:
        console.log('PUBLISH SINGLE CARD', action);
        const selectedCardsToPublish = this.userDataService.uploadedCards.find(card => card.id === action.card.id);
        this.userDataService.sendModelForReview(selectedCardsToPublish);
        this.webNavService.footerMode = FOOTER_MODE.PORTFOLIO;
        this.isModelFeedShown = false;

        break;
      case CARD_CONTROL_BTN.BUY_MODEL:
        this.utilityService.notImplemented();
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
        this.updateFilteredModelsState();
        this.utilityService.triggerHaptics();
        console.log('Portfolio, onModelFeedBackBtnClick: ');

        switch (true) {
          default:
            // this.onPortfolioSwitchPanelClick(this.currentPortfolioSwitchPanelMode);
            this.isModelFeedShown = false;
            this.webNavService.footerMode = FOOTER_MODE.PORTFOLIO;
            this.router.navigate(['portfolio']);
            break;
        }
        this.isModelFeedShown = false;
        break;
      default:
        break;
    }
  }

  public onCollectionSelected(collectionSelected: ICardCollection): void {
    if(collectionSelected.isSelected) {
      this.webNavService.footerMode = FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE
    } else {
      this.webNavService.footerMode = FOOTER_MODE.LIBRARY_SELECT_COLLECTION_TO_MOVE_DISABLED
    }
  }

  private setFilteringMode(filterMode: NAVPANEL_ACTION_TYPE) {
    this.filterCardMode = filterMode
    const navpanelItems: INavpanelAction[] = [
      {actionName: 'All Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS },
      {actionName: 'Saved Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_SAVED_MODELS },
      {actionName: 'By You Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_UPLOADED_MODELS },
      {actionName: 'Purchased Models', actionType: NAVPANEL_ACTION_TYPE.FILTER_PURCHASE_MODELS },
    ]
    navpanelItems.find(item => item.actionType === this.filterCardMode).isSelected = true;
    this.portfolioNavpanelItems = navpanelItems;
  }

  public openRenameDialog() {
    const dialogConfig: WebDialogData = {
      heading: 'Rename Model',
      mainBtnTitle: 'Rename',
      inputPlaceHolder: 'Enter new name',
      inputValue: this.cardToEdit.cardTitle
    }
    const dialogRef = this.dialog.open(WebpopupdialogComponent, {data: dialogConfig, restoreFocus: false, panelClass: 'webpopup-overlay'},);

    const sub = dialogRef.componentInstance.webpopupdialogueMainBtnClick.subscribe((newName: string) => {
      console.log('Model to rename:', this.cardToEdit);
      this.updateCurrentCardTitle(newName);
      this.dialog.closeAll();
      sub.unsubscribe();
    });

  }

  public updateCurrentCardTitle(newName: string) {
    const editCardIndex = this.userDataService.uploadedCards.findIndex((card) => {
      return card.cardPosterMobile === this.cardToEdit.cardPosterMobile && card.cardTitle === this.cardToEdit.cardTitle
    })
    this.cardToEdit.cardTitle = newName;
    this.userDataService.uploadedCards[editCardIndex].modifiedDate = this.utilityService.getCurrentDateWithNanoseconds();
    this.userDataService.uploadedCards[editCardIndex].cardTitle = newName;

    this.cardService.updateModelPostRequest(this.cardToEdit).then((res) => {
      this.utilityService.showSnackBar('Model renamed succesfully', 500);
    }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on rename: ${JSON.stringify(error.error)}`, 500));
  }

  public openCancelPublishingCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to cancel publishing model "${card.cardTitle}" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // OUTDATED LOGIC !!!!!!! SHOULD BE REWORKED !!!!!!!

        this.userDataService.cancelModelPublishing(card);
        this.webNavService.footerMode = FOOTER_MODE.LIBRARY;
        const indx = this.userDataService.uploadedCards.findIndex((findCard) => { return findCard.id === card.id});
        this.userDataService.uploadedCards[indx].purchaseStatus = CARD_PURCHASE_STATUS.UPLOADED;
        this.webNavService.footerMode = FOOTER_MODE.EDIT_COLLECTION;
        this.isModelFeedShown = false;

        this.cardService.cancelReviewModelPostRequest(card).then((resp) => {
            this.utilityService.showSnackBar('Review cancelled succesfully.', 500);
          }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on cancelling review: ${JSON.stringify(error.error)}`, 500));

      }
    });
  }

  public openDeleteCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {
      data: {
        dialogTitle: undefined,
        dialogText: `This model will be deleted from your library`,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.userDataService.deleteCardFromPortfolio(card);
        this.isModelFeedShown = false;
        this.webNavService.footerMode = FOOTER_MODE.PORTFOLIO;

        this.updateFilteredModelsState();

        this.cardService.deleteModelPostRequest(card).then((resp) => {
          this.utilityService.showSnackBar('Model deleted succesfully.', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on delete: ${JSON.stringify(error.error)}`, 500));

      }
    });
  }

  public getLayoutClass(): string {
    if(environment.type === 'mobile') return 'mobilelayout';
    if(environment.type === 'web') return 'weblayout';
  }

  public ngOnDestroy(): void {
    this.uploadErrorSub.unsubscribe();
    this.webpanelSwitchPanelChangeSubscription.unsubscribe();
    this.routeUidSub?.unsubscribe();
  }

}
