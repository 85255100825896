<app-navbar [@navbarmodelFeedAnimation]="navbarAnimationState" (@navbarmodelFeedAnimation.done)="onNavbarAnimationDone($event)"  #navbarComponent [footerBarMode]="currentFooterMode" [additionalData]="additionaNavData" [disableMainActionBtn]="isNoPurchasePossible(collectionDetailsSource)" (footermodeChange)="onFootermodeChanged($event)" (navbarButtonClick)="onNavbarBtnClicked($event)"  ></app-navbar>
<app-search #searchRef *ngIf="isSearchActive" (cancelSearchClicked)="onCancelSearchClicked()" >
  <div class="library-content-area last-slider-no-switch-panel" [@inOutAnimation]>
    <app-card-horizontal-slider [title]="'Sculptures'" [isSmallSize]="true" [sourceCards]="userDataService.getSavedCards()" style="margin-top: 20px"></app-card-horizontal-slider>
    <app-card-horizontal-slider [title]="'Models'" [isSmallSize]="true" [sourceCards]="userDataService.userPurchasedCards"></app-card-horizontal-slider>
    <app-card-horizontal-slider [title]="'Historical'" [isSmallSize]="true" [sourceCards]="userDataService.userReviewPublishRejectModels"></app-card-horizontal-slider>
    <!-- <app-card-horizontal-slider [title]="'Building'" [isSmallSize]="true" [sourceCards]="filteredAllModels"></app-card-horizontal-slider> -->
  </div>
</app-search>
<div [@cardFeedAnimation]="currentAnimationState"  class="home-page" ngClass.lt-sm="home-page-lt-sm" ngClass.lt-md="home-page-lt-md" ngClass.gt-sm="home-page-gt-sm" ngClass.gt-lg="home-page-gt-lg">


    <app-models-card #collectionCardsFeed [feedIsVisible]="!isModelFeedVisible" [cardsRaw]="cards"  [noDataMessage]="cardsNoData" (cardClicked)="onCollectionClicked($event)" (cardPressed)="onModelCardPressed()" (cardVisibilityChanged)="onCardVisibilityChanged($event)" (actionBtnClicked)="onCardCollectionControlBtnClicked($event)" ></app-models-card>
    <app-collection-details #collectionDetailsComponent  *ngIf="isCollectionDetailsShown" [@feedCollectionDetailsAnimation]="isCollectionDetailsShown" (@feedCollectionDetailsAnimation.start)="onCollectionDetailsAnimationStart($event)" (@feedCollectionDetailsAnimation.done)="onCollectionDetailsAnimationDone($event)" (collectionDetailsThumbClick)="onCollectionDetailsThumbClick($event)" (controlButtonClick)="onCollectionDetailsControlButtonClicked($event)"    [sourceCollection]="collectionDetailsSource" ></app-collection-details>
    <div [@homeOverscrollAnimation]="isModelFeedVisible" class="home-overscroll-background" *ngIf="isModelFeedVisible"></div>
    <app-navpanel  *ngIf="isNavpanelShown" #navPanelRef [@navpanelAnimation]="navpanelAnimationState" (@navpanelAnimation.done)="onNavpanelAnimationDone($event)"  [navpanelActionItems]="homeNavpanelItems" (hideNavpanelEvent)="onHideNavpanelEventEmitted()" (navpanelActionClickEvent)="onNavpanelActionItemClicked($event)" (navpanelDestroyEvent)="onNavpanelDestroy()"></app-navpanel>
    <!-- <app-navpanel *ngIf="isNavpanelShown"  #navPanelRef [@navpanelAnimation]="navpanelAnimationState" (@navpanelAnimation.done)="onNavpanelAnimationDone($event)"  [navpanelActionItems]="libraryNavpanelItems" (hideNavpanelEvent)="onHideNavpanelEventEmitted()" (navpanelActionClickEvent)="onNavpanelActionItemClicked($event)" (navpanelDestroyEvent)="onNavpanelDestroy()"></app-navpanel> -->

    <app-models-card @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" *ngIf="isModelFeedVisible" [startPosition]="modelFeedCardStartPosition"[feedIsVisible]="isModelFeedVisible" [cardsRaw]="modelFeedCards"  [feedMode]="CARDS_MODE.MODEL_CARD_FEED" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)"  (actionBtnClicked)="onCardCollectionControlBtnClicked($event)" (cardVisibilityChanged)="onCardVisibilityChanged($event)" ></app-models-card>
    <!-- <app-card-select-panel [@fullscreenPanelAnimation] *ngIf="isCardSelectShown" [title]="'Select Model to Purchase'" [singleSelectMode]="true" [disableClick]="currentPurchaseState === PURCHASE_PROCESS.PROCESSING || currentPurchaseState === PURCHASE_PROCESS.VERIFICATION" [ngClass]="currentPurchaseState"  [showCardPrice]="true" [cardsToSelect]="cardsAvailableToPurchase" [userSelectedCards]="[]" (cardSelected)="onCardToPurchaseSelected($event)" ></app-card-select-panel> -->
    <app-card-buy-panel [@fullscreenPanelAnimation]  *ngIf="isCardSelectShown" [title]="'Select Model to Purchase'" [disableClick]="currentPurchaseState === PURCHASE_PROCESS.PROCESSING || currentPurchaseState === PURCHASE_PROCESS.VERIFICATION" [ngClass]="currentPurchaseState" [cardsToSelect]="cardsAvailableToPurchase" (cardSelected)="onCardToPurchaseSelected($event)" ></app-card-buy-panel>

 </div>
