import { UtilityFunctionsService } from './../../shared/providers/utility-functions.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { CollectionDetailsComponent } from './../../shared/components/collection-details/collection-details.component';
import { feedCollectionDetailsAnimation, fullscreenPanelAnimation, homeOverscrollAnimation, inOutAnimation, modelFeedAnimation, navbarDelayAnimation, navbarmodelFeedAnimation, navpanelAnimation } from './../../shared/providers/animations-list';
import { SettingsService } from './../../shared/providers/settings.service';
import { ModelCardRendered, CARDS_MODE, ModelCardRenderedSelection, CARD_PURCHASE_STATUS, INavpanelAction, NAVPANEL_ACTION_TYPE, ICardAction, CARD_CONTROL_BTN, COLLECTION_CONTROL_BTN, Cards_Page_State } from './../../models/card';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, Inject } from '@angular/core';
import { ICard, NoDataMessage } from 'src/app/models/card';
import { ModelsCardComponent } from 'src/app/shared/components/models-card/models-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import { pageFadingAnimation, fullscreenCardAnimation, cardFeedAnimation, cardFeedNavbarAnimation } from 'src/app/shared/providers/animations-list';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';
import { AnimationEvent } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { ICardCollection } from 'src/app/models/collection';
import { IAdditionalFooterData } from 'src/app/models/common';
import { SearchComponent } from 'src/app/shared/components/search/search.component';
import { PURCHASE_PROCESS, FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { CustomRollbar, RollbarService } from 'src/app/shared/providers/rollbarlog.service';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';

// import { Toast } from '@capacitor/toast';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    pageFadingAnimation,
    fullscreenCardAnimation,
    cardFeedAnimation,
    cardFeedNavbarAnimation,
    feedCollectionDetailsAnimation,
    modelFeedAnimation,
    navbarDelayAnimation,
    homeOverscrollAnimation,
    navbarmodelFeedAnimation,
    fullscreenPanelAnimation,
    navpanelAnimation,
    inOutAnimation
],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('collectionCardsFeed', { static: false })
  public collectionCardsFeed: ModelsCardComponent;

  @ViewChild('modelCardsFeed', { static: false })
  public modelCardsFeedRef: ModelsCardComponent;

  @ViewChild('collectionDetailsComponent', { static: false })
  public collectionDetailsComponent: CollectionDetailsComponent;

  @ViewChild('navbarComponent', { static: true })
  public navbarComponent: NavbarComponent;

  @ViewChild('searchRef')
  public searchRef: SearchComponent;

  public PURCHASE_PROCESS = PURCHASE_PROCESS;
  public CARDS_MODE = CARDS_MODE;

  public cards: ICard[] = [];
  public homeCollections: ICardCollection[] = [];

  public cardsNoData: NoDataMessage;

  public collectionDetailsSource: ICardCollection = {
    collectionId: undefined,
    collectionTitle: 'basic',
    collectionCards: [],
    accessType: 'Private',
    showFullscreenBackgroundPreview: false,
    preloadSettings: {
      enablePreload: true,
      numberOfCardsToPreload: 9,
    }
  }

  public cardsAvailableToPurchase: ModelCardRenderedSelection[] = [];

  public detailsSource: ModelCardRendered;
  public lessBtnSubscription: Subscription;
  public moreBtnSubscription: Subscription;
  public cardPurchaseStateChangeSubscription: Subscription;


  public modelFeedCardStartPosition: number;

  // DO NOT FORGET TO RETURN
  public isCardSelectShown: boolean = false;
  public isSearchActive: boolean = false;
  // public isCardSelectShown: boolean = true;

  public isModelFeedVisible: boolean = false;
  public isDeepLinkActivated: boolean = false;


  public currentPurchaseState: PURCHASE_PROCESS = PURCHASE_PROCESS.DEFAULT;
  public currentAnimationState: string = Cards_Page_State.CARD_FEED;
  public navbarAnimationState: string = 'default';

  public modelFeedCards: ModelCardRendered[];
  public modelCardsMock: ModelCardRendered[];
  public modelCardsMockSource: ModelCardRendered[] = JSON.parse(JSON.stringify(this.mockDataService.modelCardsMockSourceShort));

  public additionaNavData: IAdditionalFooterData = {priceData: undefined};
  public FOOTER_MODE = FOOTER_MODE;
  // DO NOT FORGET TO RETURN
  public currentFooterMode: FOOTER_MODE = FOOTER_MODE.DEFAULT;
  // public currentFooterMode: FOOTER_MODE = FOOTER_MODE.HOME_SEARCH;


  public isCollectionDetailsShown: boolean = false;
  public isCollectionDetailsHiding: boolean = false;
  public currentCard: ICard;


  public homeNavpanelItems: INavpanelAction[] = [];

  public isNavpanelShown: boolean;
  public navpanelAnimationState: string;
  public isNavpanelHidden: boolean;
  public cardsToPurchaseIds: string[];
  public firebaseUserSubscription: Subscription;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private mockDataService: MockDataService,
    public settingsService: SettingsService,
    public cardService: CardsService,
    public userDataService: UserDataService,
    public firebaseAuth: FirebaseAuthService,
    private cdr: ChangeDetectorRef,
    @Inject(RollbarService) private rollbar: CustomRollbar,
    public utilityService: UtilityFunctionsService
    ) {

      const longFeed = [];
      this.modelCardsMockSource.forEach((el)=>{
        longFeed.push(el);
      })

      const st = performance.now()
      console.log('START: ', st );
      const min = 1;
      const max = 100;
      for (let index = 0; index < 10; index++) {
        this.modelCardsMockSource.forEach(arrEl => {
          const synteticCard = JSON.parse(JSON.stringify(arrEl));
          synteticCard.cardTitle = arrEl.cardTitle + ` Clone ${index}`;
          synteticCard.cardContentMobile = arrEl.cardContentMobile + `?=${index}`;
          synteticCard.cardPrice = Math.floor(Math.random() * (max - min + 1) + min);
          longFeed.push(synteticCard);
        });
      }
      const fin = performance.now()
      console.log('FINISH: ', fin - st )
      this.modelCardsMock = longFeed;
      this.collectionDetailsSource.collectionCards = this.modelCardsMock
      this.collectionDetailsSource.collectionCards = this.modelCardsMockSource


     }

  async ngOnInit() {

    this.route.queryParamMap.subscribe(async queryParams => {
      const modelId = queryParams.get('modelId');
      const collectionId = queryParams.get('collectionId');
      if(modelId) {
        const model = await this.cardService.getModelById(modelId);
          if(model) {
            this.isDeepLinkActivated = true;
            console.log('MODEL RECIEVED BY ID : ', model);
            this.isModelFeedVisible = true;
            this.modelFeedCardStartPosition = 0;
            this.modelFeedCards = model
          }
        }
      if(collectionId) {
        // SETTIMEOUT TEMPORARY HACK
          const collections = await this.cardService.getCollectionByIdRequest(collectionId);
          // const collection = this.cardService.getCollectionFromHomeFeedById(collectionId);
          if(collections) {
            this.isDeepLinkActivated = true;
            console.log('COLLECTION RECIEVED BY ID : ', collections);
            this.isCollectionDetailsShown = true;
            this.collectionDetailsSource = collections[0];
            this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED;
          }

      }
     });

    this.homeCollections = await this.cardService.getCollectionFeed();
    this.setMockCollectionCardsForEmptyCols(this.homeCollections);
    this.cards = this.cardService.convertCollectionsToCardsFeed(this.homeCollections);

    this.cardService.homeFeedGetTime = performance.now();
    console.log('PERF_ INIT HOME homeFeedGetTime TIME:', this.cardService.homeFeedGetTime);

    this.currentCard = this.cards[0];
    this.cardsNoData = {text: 'Something went wrong with cardFeed: no cards data'};


    // setTimeout(() => {
    //   this.cardService.triggerMoreBtnSubject();
    //   this.currentFooterMode = FOOTER_MODE.CARD_BUY_SECTION;
    //   this.isCardSelectShown = true;
    // }, 2000);


    this.moreBtnSubscription = this.cardService.moreBtnSubject.subscribe((currentCard: ICard) => {
      console.log(`Home feed more click btn happened, ${currentCard?.cardTitle}`);
      this.collectionCardsFeed.removeLikeTouchedClass();
      this.cardService.collectionDetailsInitTimeStart = performance.now();
      this.detailsSource = currentCard;
      // To avoid situation, when card from 'ModelFeed' set as current card on back btn click;
      if(this.cardService.getCollectionCardSourceByTitle(currentCard.cardContentMobile)) {
        this.cardService.setLastCollectionCard(currentCard);
      }
      this.collectionDetailsSource = this.setPropsForCollectionDetailsBasedOnCard(currentCard, this.collectionDetailsSource);
      this.collectionDetailsSource.collectionCards = this.getCardsFromCollectionById(currentCard.id)

      this.modelFeedCards = this.collectionDetailsSource.collectionCards;
      this.isCollectionDetailsShown = true;
      this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
    })


    this.lessBtnSubscription = this.cardService.lessBtnSubject.subscribe(async (currentCard: ICard) => {
      console.log('LessBtnSubscription triggered')
        if(this.currentFooterMode === FOOTER_MODE.COLLECTION_DETAILS || this.currentFooterMode === FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED) {
          // await this.collectionDetailsComponent.nativeScrollToTop();
          this.collectionDetailsComponent.removeLikeTouchedClass();

        //  await this.collectionDetailsComponent.scrollToTopOfCollectionDetails();
        }
        this.isCollectionDetailsShown = false;
        this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_TRANSITION_TO_HOME;
        this.navbarAnimationState = 'default';
        this.navbarAnimationState = 'transitionToFeed';
        this.collectionCardsFeed.setActiveComponentCardToCardService();
    })

    this.cardPurchaseStateChangeSubscription = this.cardService.onCardPurchaseStateChange.subscribe((purchaseState) => {
      this.currentPurchaseState = purchaseState;
      switch (purchaseState) {
        case PURCHASE_PROCESS.ERROR:
          this.additionaNavData.priceData = undefined
          this.collectionDetailsSource.collectionCards.forEach(card => card.isSelected = false);
          break;
        case PURCHASE_PROCESS.SUCCESS:
          console.log('PURCHASE_PROCESS.SUCCESS -> HOME COMPONENT');
          this.additionaNavData.priceData = undefined
          this.collectionDetailsSource.collectionCards.forEach(card => card.isSelected = false);


          this.cardsToPurchaseIds.forEach((purchasedId) => {
            const purchasedCardIndexHome = this.modelFeedCards.findIndex((cardToFind) => {
              return cardToFind.id === purchasedId;
            })
           this.modelFeedCards[purchasedCardIndexHome].purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;

            // Case, user now is on model feed;
            if(this.isModelFeedVisible) {
              const purchasedCardIndexModelFeed = this.modelCardsFeedRef.cards.findIndex((cardToFind) => {
                return cardToFind.id === purchasedId;
              })
                this.modelCardsFeedRef.cards[purchasedCardIndexModelFeed].purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
              }
          })

          this.getListOfUserPurchasedModelsLocally();

           // Case, when user in the 'buy' menu
           if(this.isCardSelectShown) {
              this.cardsAvailableToPurchase = this.collectionDetailsSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED);
           }

          this.utilityService.showSnackBar('Purchase added to Library', 750);
          break;
        default:
          break;
      }
      this.cdr.detectChanges();
    })

    this.cardService.homeBasicInitTime = performance.now();
    console.log('PERF_ INIT HOME (End of NgOnInit) homeBasicInitTime TIME:', this.cardService.homeBasicInitTime);


    if(this.firebaseAuth.currentUser === undefined) {
      this.firebaseUserSubscription = this.firebaseAuth.firebaseUser.subscribe(async (currentUser) => {
        if(currentUser) this.getListOfUserPurchasedModelsLocally();
      });
    } else {
      this.getListOfUserPurchasedModelsLocally()
    }

    if(this.cardService.userKnowsHowToScrollFeed()) return;
    else {
      setTimeout(() => {
        this.collectionCardsFeed.showAnimationScrollForNextModelCard()
      }, 2000);
    }


  }

  private getListOfUserPurchasedModelsLocally(): void {
    this.cardService.getListOfUserPurchasedModels().then((resp: any) => {
      let purchasedCardIdArray = [];
      try {
        purchasedCardIdArray = resp?.model_ids?.map((res) => {return res});
      } catch (error) {
        console.error(error);
        purchasedCardIdArray = [];
      }


      this.homeCollections.forEach((col) => {
        col.collectionCards.forEach((card) => {
          const isExistsInPurchasedResponse = purchasedCardIdArray?.findIndex(cardToFind => cardToFind === card.id) >= 0;
          if(isExistsInPurchasedResponse)  {
            if(!this.userDataService.isCardPurchasedCheckById(card.id)) {
              this.userDataService.purchaseCards([card], col);
              // console.log('set exist in purchase for: ', card.cardTitle, ' id: ', card.id);
              card.purchaseStatus = CARD_PURCHASE_STATUS.PURCHASED;
            }

          }
        })
      })
    })

  }

  private getCardsFromCollectionById(id?: string): ICard[] {
    if(id) {
      const targetCol = this.homeCollections.find(col => {
        return col.collectionId === id;
      })
      return targetCol?.collectionCards
    } else return []
  }

  private setMockCollectionCardsForEmptyCols(col: ICardCollection[]): ICardCollection[] {
    col.forEach(collection => {
      if(collection?.collectionCards.length === 0) {
        collection.collectionCards = JSON.parse(JSON.stringify(this.mockDataService.modelCardsMockSourceShort));
        const leng = Math.floor(Math.random() * (9 - 1) + 1);
        collection.collectionCards.length = leng;
      }
    });
    return col
  }

  private setPropsForCollectionDetailsBasedOnCard(card: ICard, collection: ICardCollection): ICardCollection {
    const resultCollection = [card].map((cardItem) => {
      return {
        collectionId: cardItem.id, // because it's collection ! (not card)
        collectionTitle: cardItem.cardTitle,
        collectionDescription: cardItem.cardDescription,
        collectionPosterMobile: cardItem.cardPosterMobile,
        collectionContentMobile: cardItem.cardContentMobile,
        collectionAuthor: cardItem.cardAuthor,
        collectionCards: collection.collectionCards,
        showFullscreenBackgroundPreview: false,
        preloadSettings: collection.preloadSettings
      }
    })
    return resultCollection[0]
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   console.log('CORDOVA RESPONSE')
    //   this._window().store.register({type: this._window().store.CONSUMABLE, id: 'generic.3dmodel.purchase'});
    //     this._window().store.refresh();
    //   const product = this._window().store.get('generic.3dmodel.purchase');
    //   console.log(product);
    // }, 500);
  }
  public onCollectionClicked(collectionNonConverted: ModelCardRendered) {

    this.currentFooterMode = this.currentFooterMode === FOOTER_MODE.DEFAULT || this.currentFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    this.currentFooterMode = this.currentFooterMode === FOOTER_MODE.DEFAULT || this.currentFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }

  public onModelCardPressed() {
    this.currentFooterMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public onFootermodeChanged(mode: FOOTER_MODE) {
    this.currentFooterMode = mode;
  }

  public onNavbarBtnClicked(navbarBtn: NAVBAR_BTN) {
    console.log('clicked ', navbarBtn)
    switch (navbarBtn) {
      case NAVBAR_BTN.TO_TOP:
        this.collectionDetailsComponent.nativeScrollToTopLight();
        break;
      case NAVBAR_BTN.SEARCH:
        // BE CAREFUL: It's navbar action, but by default first action triggered from MODEL CARD;
        this.searchRef.searchBarFocus();
        break;
      case NAVBAR_BTN.BUY:
        if(this.currentFooterMode === FOOTER_MODE.CARD_BUY_SECTION) {
          console.log('BUY BTN CLICKED');
          const purchasedCards = this.collectionDetailsSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED && card.isSelected);
          console.log(' SELECTED CARD TO PURCHASE: ', purchasedCards);

          this.cardsToPurchaseIds = purchasedCards.map(card => card.id);
          this.cardService.processConsumableInAppPurchaseRequest(purchasedCards);
          // const purchasedCards = this.collectionDetailsSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED && card.isSelected);
          // this.userDataService.purchaseCards(purchasedCards, this.collectionDetailsSource);

          // this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          // this.isCardSelectShown = false;
          // this.additionaNavData.priceData = undefined ;
          // this.utilityService.showSnackBar('Success!', 0);
        } else {
          this.additionaNavData.priceData = undefined;
          this.cardsAvailableToPurchase = this.collectionDetailsSource.collectionCards.filter(card => card.purchaseStatus !== CARD_PURCHASE_STATUS.PURCHASED && card.cardPrice);
          this.collectionDetailsSource.collectionCards.forEach(card => card.isSelected = false);
          this.currentFooterMode = FOOTER_MODE.CARD_BUY_SECTION;
          this.isCardSelectShown = true;
        }
        break;
      case NAVBAR_BTN.BACK:
        if(this.isSearchActive) {
          this.currentFooterMode = FOOTER_MODE.DEFAULT;
          this.isSearchActive = false;
        } else {
          this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.isCardSelectShown = false;
        }
        break;
      case NAVBAR_BTN.SELECT_ALL:
        this.utilityService.notImplemented();
        break;
      case NAVBAR_BTN.MORE_OPTIONS:
        if(this.userDataService.isCollectionExitsInSaved(this.collectionDetailsSource)) {
          this.homeNavpanelItems = [
            {actionName: 'Remove Collection', actionType: NAVPANEL_ACTION_TYPE.LIKE_COLLECTION },
            {actionName: 'Share Collection', actionType: NAVPANEL_ACTION_TYPE.SHARE_COLLECTION },
          ]
        } else {
          this.homeNavpanelItems = [
            {actionName: 'Add to Library', actionType: NAVPANEL_ACTION_TYPE.LIKE_COLLECTION },
            {actionName: 'Share Collection', actionType: NAVPANEL_ACTION_TYPE.SHARE_COLLECTION },
          ]
        }

        this.isNavpanelShown = true;
        break;
      case NAVBAR_BTN.NOT_IMPL:
        this.utilityService.notImplemented();
        break;
      default:
        break;
    }
  }

  public onCardVisibilityChanged(card: ModelCardRendered) {
    this.currentFooterMode = FOOTER_MODE.DEFAULT;
  }

  public onNavbarAnimationDone(event: AnimationEvent): void {
    console.log('onNavbarAnimationDone start: ', event?.toState);

    // if(event.toState === 'void') {
    //   this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_TRANSITION_TO_HOME;
    //   // this.navbarAnimationState = 'transitionToFeed';
    // }
  }

  public onCollectionDetailsAnimationStart(event: AnimationEvent): void {
    console.log('onCollectionDetailsAnimationStart start');
    // this.isCollectionDetailsHiding = true;

    if(event.toState === 'void') {
      this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_TRANSITION_TO_HOME;
      // this.navbarAnimationState = 'transitionToFeed';
    }
  }

  public onCollectionDetailsAnimationDone(event: AnimationEvent): void {
    console.log('onCollectionDetailsAnimationStart done');
    if(event.toState !== 'void') {
      this.cardService.collectionDetailsInitTimeEnd = performance.now();
      console.log('COLLECTION DETAILS INIT TIME: ', this.cardService.collectionDetailsInitTimeEnd - this.cardService.collectionDetailsInitTimeStart);
    }

    // this.isCollectionDetailsHiding = false;

    if(event.toState === 'void') {
      this.currentFooterMode = FOOTER_MODE.DEFAULT;
      this.collectionCardsFeed.startPlayVisibleCollectionCard();
      this.navbarAnimationState = 'default';
    }
  }

  public onCardToPurchaseSelected(cardsToPurch: ModelCardRenderedSelection[]): void {
    // setting additional purchase data;
    const isAllCardsSelected = this.collectionDetailsSource.collectionCards.length === cardsToPurch.length;
    if(isAllCardsSelected) this.additionaNavData.selectToggleLabel = 'Cancel';
    else this.additionaNavData.selectToggleLabel = 'Select All';

    const selectedCardPrice = cardsToPurch.reduce((acc, val) => {
      return acc = acc + val.cardPrice;
    }, 0)
    this.additionaNavData.priceData = '$' + selectedCardPrice.toString() + '.00' ;
    console.log(selectedCardPrice)
  }

  public onCollectionDetailsControlButtonClicked(buttonName: COLLECTION_CONTROL_BTN): void {
    switch (buttonName) {
      case COLLECTION_CONTROL_BTN.ANONYMOUS_USER:
        this.navbarComponent.handleUnauthorizedUserActionDetected();
        break;
      default:
        break;
    }
  }

  public onCollectionDetailsThumbClick(thumbNumber: number): void {
    this.modelFeedCards = this.collectionDetailsSource.collectionCards;
    console.log('this.modelFeedCards: ', this.modelFeedCards)

    // const temporaryFeedArr: ModelCardRendered[] = JSON.parse(JSON.stringify(this.modelCardsMock));
    // const selectedItem = temporaryFeedArr.splice(thumbNumber,1);
    // temporaryFeedArr.unshift(selectedItem[0]);
    // this.modelsFeedSource = temporaryFeedArr;
    if(this.settingsService.devicePlatform === 'ios') {
      Haptics.impact({
        style: ImpactStyle.Light
      });
    }
    this.collectionCardsFeed.disposeAllVideosExceptCurrent();
    this.cardService.modelFeedInitStart = performance.now();

    this.navbarAnimationState = 'modelFeed';
    this.modelFeedCardStartPosition = thumbNumber;
    console.log('on thumb click (home)');
    this.currentFooterMode = FOOTER_MODE.DEFAULT;
    this.modelCardsMock[0].visible = false;
    this.isModelFeedVisible = true;

    console.log('NAVBAR ANIM STATE:', this.navbarAnimationState)
  }

  public onNavpanelActionItemClicked(item: INavpanelAction): void {
    console.log(item.actionName);
    this.handleNavpanelAction(item);
    this.isNavpanelShown = false;
  }

  public handleNavpanelAction(action: INavpanelAction): void {
    const actionName = action.actionType
    switch (true) {
      case actionName === NAVPANEL_ACTION_TYPE.LIKE_COLLECTION:
          this.collectionDetailsComponent.dispatchLikeClick();
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_COLLECTION:
        this.collectionDetailsComponent.onShareBtnClicked(this.collectionDetailsSource);
        break;
      case actionName === NAVPANEL_ACTION_TYPE.SHARE_MODEL:
        this.modelCardsFeedRef.onShareBtnClicked(this.cardService.getCurrentCard());
        break;
      case actionName === NAVPANEL_ACTION_TYPE.UNLIKE_CARD:
        this.modelCardsFeedRef.dispatchCurrentCardLikeClick();
        break;
      default:
        break;
    }
  }

  public onHideNavpanelEventEmitted(): void {
    this.isNavpanelShown = false;
  }

  public onNavpanelDestroy(): void {
    this.navpanelAnimationState = 'visible';
    this.isNavpanelHidden = false;
  }

  public onNavpanelAnimationDone(event: AnimationEvent): void {
    console.log('animation done');
    console.log(event.phaseName);
    if(event.toState !== 'void') {
      if(this.navpanelAnimationState === 'hidden') this.isNavpanelHidden = true;
    }
  }

  public onCardCollectionControlBtnClicked(action: ICardAction): void {
    console.log('onCardCollectionControlBtnClicked: ', action)
    this.utilityService.triggerHaptics();
    switch (action.controlAction) {
      case CARD_CONTROL_BTN.ANONYMOUS_USER:
        this.navbarComponent.handleUnauthorizedUserActionDetected();
        break;
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
      case CARD_CONTROL_BTN.CONTROL_ACTION_FOR_LIKED:
        if(this.userDataService.isCardExitsInSaved(action.card)) {
          this.homeNavpanelItems = [
            {actionName: 'Remove from Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
            {actionName: 'Share Model', actionType: NAVPANEL_ACTION_TYPE.SHARE_MODEL },
          ]
        } else {
          this.homeNavpanelItems = [
            {actionName: 'Add to Library', actionType: NAVPANEL_ACTION_TYPE.UNLIKE_CARD },
            {actionName: 'Share Model', actionType: NAVPANEL_ACTION_TYPE.SHARE_MODEL },
          ]
        }
        this.isNavpanelShown = true;
        break;
      case CARD_CONTROL_BTN.CONTROL_GO_TO_DETAILS:
        if (!this.isModelFeedVisible) {
          this.detailsSource = action.card;
          this.currentCard = action.card;
          this.cardService.triggerMoreBtnSubject();
        } else {
          this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.isModelFeedVisible = false;
          this.navbarAnimationState = 'default';
        }
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
        if(this.isDeepLinkActivated && this.isCollectionDetailsShown) {
          this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS_FOR_SHARED;
          this.isModelFeedVisible = false;
          break;
        }
        if(this.isDeepLinkActivated && !this.isCollectionDetailsShown) {
          this.isDeepLinkActivated = false;
          this.currentFooterMode = FOOTER_MODE.DEFAULT;
          this.isModelFeedVisible = false;
          this.navbarAnimationState = 'default';
          this.collectionCardsFeed.setFirstCardExplicitVisible();
          this.collectionCardsFeed.setActiveComponentCardToCardService();
          break;
        }
          this.currentFooterMode = FOOTER_MODE.COLLECTION_DETAILS;
          this.isModelFeedVisible = false;
          this.navbarAnimationState = 'default';
        break
      case CARD_CONTROL_BTN.SEARCH:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
        // this.isSearchActive = true;
        // this.currentFooterMode = FOOTER_MODE.SEARCH;
        break;
      case CARD_CONTROL_BTN.BUY_MODEL:
        this.cardsToPurchaseIds = [action.card.id];
        this.cardService.processConsumableInAppPurchaseRequest([action.card]);
        break;
      default:
        break;
    }
  }

  public isNoPurchasePossible(collection: ICardCollection): boolean {
    if(collection?.collectionCards?.length > 0) {
      const isAnyCardPossibleToPurchase = collection.collectionCards.some(card => card.cardPrice && card.cardPrice > 0);
      // const isAny = collection.collectionCards.every(card => card.cardPrice && card.cardPrice > 0);
      return !isAnyCardPossibleToPurchase;
    } else return true;
  }

  public onCancelSearchClicked(): void {
    this.isSearchActive = false;
    this.currentFooterMode = FOOTER_MODE.DEFAULT;
  }

  ngOnDestroy(): void {
    this.isDeepLinkActivated = false;
    if(this.firebaseUserSubscription) this.firebaseUserSubscription.unsubscribe();
    this.lessBtnSubscription.unsubscribe();
    this.moreBtnSubscription.unsubscribe();
    this.cardPurchaseStateChangeSubscription.unsubscribe();
  }

}
