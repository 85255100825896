<app-navbar [footerBarMode]="currentEditCollectionFooterMode" [additionalData]="editCollectionAdditionalfooterData" (navbarButtonClick)="onNavbarBtnClick($event)" (footermodeChange)="onFootermodeChanged($event)"></app-navbar>
<div class="edit-collection-page ">
</div>
<!-- <app-edit-collaborators [@fullscreenPanelAnimation] #editCollaboratorsPanelRef [collaborators]="editCollectionSource.collaborators" *ngIf="isEditCollaboratorsShown" (reviewCollaboratorModelsClick)="onReviewCollaboratorModeClicked()"></app-edit-collaborators> -->
<app-subscription-splash [@fullscreenPanelAnimation]  *ngIf="isSubscriptionSplashShown" [tryForFreeMode]="!userDataService.isFreeUploadLimitReached && !userDataService.isTryFreeEnabled"  ></app-subscription-splash>

<app-rejected-screen [@fullscreenPanelAnimation]  *ngIf="isRejectedScreenShown" (cancelClicked)="onCancelRejectedScreenClicked()" ></app-rejected-screen>

<!-- <app-collection-select-panel [@fullscreenPanelAnimation] #cardSelectRef  *ngIf="isCollectionSelectShown" [title]="'Move to Collection.'" [sourceCollections]="filterCollectionWithoutCurrentEditOne()" [userSelectedCards]="[]" (collectionSelected)="onCollectionSelected($event)"></app-collection-select-panel> -->
<app-card-select-panel [@fullscreenPanelAnimation] #cardSelectRef  *ngIf="isCardSelectShown" [title]="cardSelectTitle" [showCardPrice]="isCardPriceShown" [cardsToSelect]="cardSelectData" [disableForSelectCards]="editCollectionSource.collectionCards" (cardSelected)="onCardSeletected($event)" (cardSelectSwitchPanelClick)="onCardSelectSwitchPanelClicked($event)"></app-card-select-panel>
<app-card-publish-panel [@fullscreenPanelAnimation] #cardPublishRef  *ngIf="isCardPublishShown" [title]="'Select Models.'" [cardsToSelect]="cardSelectData" [userSelectedCards]="[]" (cardSelected)="onCardSeletected($event)"></app-card-publish-panel>
<app-price-select-panel [@fullscreenPanelAnimation] #priceSelectRef *ngIf="isPriceSelectShown" [selectedPriceOption]="currentPrice" (priceSelected)="onPriceSelected($event)"></app-price-select-panel>
<app-card-buy-panel [@fullscreenPanelAnimation]  *ngIf="isBuySelectShown" [title]="'Select Model to Purchase'" [disableClick]="currentPurchaseState === PURCHASE_PROCESS.PROCESSING || currentPurchaseState === PURCHASE_PROCESS.VERIFICATION" [ngClass]="currentPurchaseState" [cardsToSelect]="cardSelectData" (cardSelected)="onCardToPurchaseSelected($event)" ></app-card-buy-panel>


<app-name-change-dialogue [@fullscreenPanelAnimation] *ngIf="isNameChangeDialogueShown" [inputValue]="nameDialogDefaultValue" (valueChanged)="onNameDialogValueChanged($event)"></app-name-change-dialogue>
<app-navpanel *ngIf="isNavpanelShown"  #navPanelRef [@navpanelAnimation]="navpanelAnimationState" (@navpanelAnimation.done)="onNavpanelAnimationDone($event)"  [navpanelActionItems]="editCollectionNavpanelItems" (hideNavpanelEvent)="onHideNavpanelEventEmitted()" (navpanelActionClickEvent)="onNavpanelActionItemClicked($event)" (navpanelDestroyEvent)="onNavpanelDestroy()">
</app-navpanel>
<app-models-card @modelFeedAnimation #modelCardsFeed class="feed-overlap model-feed" *ngIf="isModelFeedShown" [isLikeHidden]="true" [startPosition]="cardFeedCardStartPosition" [feedIsVisible]="isModelFeedShown" [cardsRaw]="modelCardsMock" [feedMode]="cardFeedMode" (actionBtnClicked)="onCardControlBtnClicked($event)" (cardPressed)="onModelCardPressed()" (cardClicked)="onModelCardClicked($event)" ></app-models-card>
<app-collection-details [ngClass]="{'transformed' : isNavpanelShown}"  #collectionDetailsComponent [sourceCollection]="editCollectionSource" [filterOptions]="filterCardMode" [collectionEditOptions]="collectionEditOptions"  (controlButtonClick)="onCollectionDetailsControlButtonClicked($event)"  (collectionDetailsThumbClick)="onCollectionDetailsThumbClick($event)">
  <app-card-thumbs-upload  class="model-thumb" *ngFor="let upCard of uploadService.uploadModels | callback: filterUploadCards" [uploadCard]="upCard" fxFlex="0 1 calc(33.3334% - 6.33px)" fxLayout="column" fxLayoutAlign="start center"></app-card-thumbs-upload>
</app-collection-details>

