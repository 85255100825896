import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDataService } from 'src/app/shared/providers/user-data.service';

export interface WebDialogData {
  heading: string;
  mainBtnTitle: string;
  inputPlaceHolder: string;
  inputValue: string;
  isInputHidden?: boolean;
}

@Component({
  selector: 'app-webpopupdialog',
  templateUrl: './webpopupdialog.component.html',
  styleUrls: ['./webpopupdialog.component.scss']
})
export class WebpopupdialogComponent implements OnInit {
  @Output() webpopupdialogueMainBtnClick = new EventEmitter<string>();

  inputValue: string = '';

  heading: string = '';
  mainBtnTitle: string = '';
  inputPlaceHolder: string = '';
  isInputHidden: boolean;

  constructor(
    public userDataService: UserDataService,
    private dialogRef: MatDialogRef<WebpopupdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WebDialogData
  ) {
    this.heading = data.heading;
    this.mainBtnTitle = data.mainBtnTitle;
    this.inputPlaceHolder = data.inputPlaceHolder;
    this.inputValue = data.inputValue;
    this.isInputHidden = data.isInputHidden;
  }

  ngOnInit(): void {
  }

  onMainBtnClick(): void {
    console.log('onMainBtnClick input value:', this.inputValue);
    this.webpopupdialogueMainBtnClick.emit(this.inputValue);
    this.dialogRef.close(true);
  }

}
