import { fakeBackendProvider } from './shared/providers/fake-backend';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import {BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import * as Hammer from 'hammerjs';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import {AppComponent} from './app.component';
import {MobileRoutingModule} from './mobile-routing.module';

// Modules
import {SharedModule} from './shared/shared.module';
import { Error404Component } from './pages/error404/error404.component';
import { HomeComponent } from './pages/home/home.component';
import { ConfirmationDialogComponent } from './shared/components/dialog/confirmation-dialog.component';
import { LibraryComponent } from './pages/library/library.component';

import { initializeAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, indexedDBLocalPersistence } from 'firebase/auth';


import { provideFirebaseApp } from '@angular/fire/app';
import { getApp, getApps, initializeApp } from 'firebase/app';

import { environment } from 'src/environments/environment';
import { ServiceSettingsComponent } from './pages/service-settings/service-settings.component';
import { EditCollectionComponent } from './pages/edit-collection/edit-collection.component';
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './shared/providers/rollbarlog.service';
import { WebRoutingModule } from './web-routing.module';
import { WebModule } from './web.module';
import { Capacitor } from '@capacitor/core';
import { DialogService } from './shared/providers/dialog.service';
import { MobileDialogService } from './shared/providers/dialog-mobile.service';
import { WebDialogService } from './shared/providers/dialog-web.service';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>(resolve => {
  resolvePersistenceEnabled = resolve;
});

declare global {
    interface Window {
    Intercom: any;
  }
}

class HammerConfig extends HammerGestureConfig {
  public events = ['swipe','press','tap','doubletap']
  public overrides = {
    'swipe': {direction: Hammer.DIRECTION_VERTICAL },
    'press': {time: 250},  // default: 251 ms
    'tap': {interval: 400, time: 350},
    'doubletap' : {requireFailure: 'tap'}
    // 'pan': {direction: Hammer.DIRECTION_HORIZONTAL},
  } as any;
  public options = {
    touchAction: 'auto'
  }
}

@NgModule({
    declarations: [
        AppComponent,
        Error404Component,
        HomeComponent,
        PortfolioComponent,
        ConfirmationDialogComponent,
        LibraryComponent,
        ServiceSettingsComponent,
        EditCollectionComponent,
    ],
    imports: [
        BrowserModule,
        environment.type === 'mobile' ? MobileRoutingModule : WebRoutingModule,
        environment.type === 'web' ? WebModule : [],
        HammerModule,
        NgxPageScrollCoreModule,
        // AngularFireModule.initializeApp(environment.firebaseConfig),
        // AngularFireAuthModule,
        // AngularFirestoreModule,
        // Custom modules
        SharedModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => {
            if (Capacitor.isNativePlatform()) {
                return initializeAuth(getApp(), {
                    persistence: indexedDBLocalPersistence
                });
            } else {
                return getAuth();
            }
        })
    ],
    providers: environment.rollbarFullLogEnabled ?
        [
            { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
            { provide: ErrorHandler, useClass: RollbarErrorHandler },
            { provide: RollbarService, useFactory: rollbarFactory },
            { provide: DialogService, useFactory: dialogServiceFactory, deps: [WebDialogService, MobileDialogService]},
        ] :
        [
            { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
            { provide: RollbarService, useFactory: rollbarFactory },
            { provide: DialogService, useFactory: dialogServiceFactory, deps: [WebDialogService, MobileDialogService]},
            fakeBackendProvider
        ],
    bootstrap: [AppComponent]
})

export class AppModule {
}

export function dialogServiceFactory(
  webDialogService: WebDialogService,
  mobileDialogService: MobileDialogService
): DialogService {
  return environment.type === 'web' ? webDialogService : mobileDialogService;
}
