import { Platform } from '@angular/cdk/platform';
import { MockDataService } from 'src/app/shared/providers/mock-data.service';
import { WebNavService } from './../webnavigation.service';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { SettingsService } from 'src/app/shared/providers/settings.service';
import { UploadFileService } from 'src/app/shared/providers/upload-file.service';
import { UserDataService } from 'src/app/shared/providers/user-data.service';
import { UtilityFunctionsService } from 'src/app/shared/providers/utility-functions.service';
import { ICardCollection } from 'src/app/models/collection';
import { FOOTER_MODE, NAVBAR_BTN } from 'src/app/models/menu';
import { ModelCardRendered, CARDS_MODE, CARD_CONTROL_BTN, ICardAction, NAVPANEL_ACTION_TYPE, ICard, LIBARY_MODE, SWITCH_PANEL_ITEM, SUBSCRIPTION_STATUS, ICardFeedModificator } from 'src/app/models/card';
import { modelFeedAnimation, inOutAnimation, inOutAnimationFast } from 'src/app/shared/providers/animations-list';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogData, ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog.component';
import { Subscription } from 'rxjs';
import { WebnavComponent } from '../webnav/webnav.component';
import { WebModelsCardComponent } from '../web-models-card/web-models-card.component';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';

@Component({
  selector: 'app-weblibrary',
  templateUrl: './weblibrary.component.html',
  styleUrls: ['./weblibrary.component.scss'],
  animations: [modelFeedAnimation, inOutAnimation, inOutAnimationFast]
})
export class WeblibraryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('webnavbarComponent', { static: false })
  public webnavbarComponent: WebnavComponent;

  @ViewChild('modelCardsFeed', { static: false })
  public modelCardsFeedRef: WebModelsCardComponent;

  public isModelFeedShown: boolean = false;
  public modelCardsMock: ModelCardRendered[]
  public cardFeedCardStartPosition: number;
  public cardFeedMode: CARDS_MODE = CARDS_MODE.COMPOSITE_FEED;
  public weblibraryMode: LIBARY_MODE = LIBARY_MODE.DEFAULT;
  public currentLibraryFooterMode: FOOTER_MODE;
  public cardToEdit: ICard;
  public webLibraryFeedModificator: ICardFeedModificator;
  homeCollections: ICardCollection[];

  LIBARY_MODE = LIBARY_MODE;
  SWITCH_PANEL_ITEM = SWITCH_PANEL_ITEM
  public uploadStatusSub: Subscription;
  public initilizeDataSub: Subscription;


  isMobileResolution: boolean = false;

  constructor(
    public userDataService: UserDataService,
    public platform: Platform,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public mockDataService: MockDataService,
    public webNavService: WebNavService,
    public settingService: SettingsService, public cardService: CardsService, public uploadService: UploadFileService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef,
    public firebaseAuth: FirebaseAuthService,
    @Inject(DOCUMENT) private document: any, public utilityService: UtilityFunctionsService,) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateResolution();
  }

  updateResolution() {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.isMobileResolution = window.innerWidth < 768;
    }
  }

  ngOnInit(): void {
    this.updateResolution();
    this.webNavService.footerMode = FOOTER_MODE.LIBRARY;

    this.uploadStatusSub = this.uploadService.uploadStatusSubscription.subscribe(()=> {
      this.cdr.detectChanges();
    });

    this.initilizeDataSub = this.userDataService.initilizeUserDataFinished.subscribe(async (isFinished) => {
      this.cdr.detectChanges();
    });

    // TEMPORARY FOR DEBUG !!! DELETE LATER
    // this.webNavService.currentWebSwitchPanelMode = SWITCH_PANEL_ITEM.PUBLISHED;
    // this.webNavService.footerMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
    // this.weblibraryMode = LIBARY_MODE.PUBLISH_REJECTED_REVIEW;
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      const sessionId = params['session_id'];
      if (sessionId) {
        // this.utilityService.showImportantSnackBar('Stripe Payment successful', 500);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { session_id: null },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        // HERE SHOULD BE LOGIC TO CHECK TWO TIMES IF SUBSCRIPTION STATUS CHANGED;
        let subscriptionSuccess = false;

        this.webnavbarComponent.openSubscriptionDialog(true);

        setTimeout(async () => {
           const status = await this.cardService.setSubForStatusSubscription();
           if(status === SUBSCRIPTION_STATUS.SUBSCRIBER) {
            subscriptionSuccess = true;
            this.webnavbarComponent.closeSubscriptionDialog();
            this.utilityService.showImportantSnackBar('You subscribed to 3DWay', 500);
           }
        }, 5000);
        setTimeout(async () => {
          if(subscriptionSuccess) return;
          const status = await this.cardService.setSubForStatusSubscription();
          if(status === SUBSCRIPTION_STATUS.SUBSCRIBER) {
            subscriptionSuccess = true;
            this.webnavbarComponent.closeSubscriptionDialog();
            this.utilityService.showImportantSnackBar('You subscribed to 3DWay', 500);
          }
       }, 10000);
        setTimeout(async () => {
          if(subscriptionSuccess) return;
          const status = await this.cardService.setSubForStatusSubscription();
          this.webnavbarComponent.closeSubscriptionDialog();
          if(status === SUBSCRIPTION_STATUS.SUBSCRIBER) {
            this.utilityService.showImportantSnackBar('You subscribed to 3DWay', 500);
          } else {
            this.utilityService.showImportantSnackBar('Subscription status not updated. Please, contact support.', 500);
          }
        }, 15000);
      }
    });
  }

  public isNoDataShown(): boolean {
    return this.userDataService.userReviewPublishRejectModels?.length === 0 && this.userDataService.allUserDraftCollections?.length === 0 && this.uploadService.uploadModels.length === 0 &&
           this.userDataService.savedCards.length === 0 && this.userDataService.uploadedCards.length === 0 && this.userDataService.isRecievedModelsRespFromBackend && this.userDataService.isRecievedDraftCollectionsRespFromBackend
  }


  public onPublishReviewRejectGalleryClicked(): void {
    console.log('see all clicked');
    this.webNavService.currentWebSwitchPanelMode = SWITCH_PANEL_ITEM.PUBLISHED
    this.webNavService.footerMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
    this.weblibraryMode = LIBARY_MODE.PUBLISH_REJECTED_REVIEW;
  }

  public async onSavedCollectionClicked(collection: ICardCollection): Promise<void> {
    console.log('saved collection clicked');

    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
    this.webLibraryFeedModificator = { hiddenOptionsButton: true }

    this.homeCollections = await this.cardService.getCollectionFeed();
    this.modelCardsMock = this.cardService.convertCollectionsToCardsFeed(this.homeCollections);
    this.cardFeedCardStartPosition = this.modelCardsMock.findIndex(thumb => thumb.cardPosterMobile === collection.collectionPosterMobile);
    this.isModelFeedShown = true;

    // this.utilityService.triggerHaptics();
    // this.collectionDetailsSource = collection;
    // this.collectionDetailsSource.showFullscreenBackgroundPreview = true;
    // this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_LIKED_COLLECTIONS_DEATAIL;
    // this.collectionEditOptions = {mode: 'liked'};
    // this.filterCardMode = NAVPANEL_ACTION_TYPE.FILTER_ALL_MODELS;
    // this.isCollectionDetailsShown = true;
  }

  public onPortfolioCollectionClciked(): void {
    // this.userDataService.librarySavedScrollPosition = this.libraryPageViewRef.nativeElement.scrollTop;
    this.router.navigate(['/portfolio'], {
      relativeTo: this.route,
      queryParamsHandling: 'merge'
    });
  }

  public onPublishReviewRejectModelsCardClicked(card: ICard): void {
    this.modelCardsMock = this.userDataService.userReviewPublishRejectModels;
    this.cardFeedCardStartPosition = this.modelCardsMock.findIndex(thumb => thumb.cardPosterMobile === card.cardPosterMobile);

    this.webNavService.footerMode = FOOTER_MODE.DEFAULT;
    this.isModelFeedShown = true;

    console.log('publish review reject models card clicked', card);

  }

  public async onDefaultCollectionOrganizeBtnClick(btnName: string): Promise<void> {
    // if(btnName === 'Organize') {
    //   this.currentLibraryFooterMode = FOOTER_MODE.LIBRARY_SELECT_MODELS_DISABLED;
    //   this.userDataService.allUserCards.forEach(card => card.isSelected = false);
    //   this.cardSelectData = this.userDataService.allUserCards;
    //   this.isCardPriceShown = false;
    //   this.isCardSelectShown = true;
    //   setTimeout(() => {
    //     this.cardSelectRef.showSwitchPanel();
    //     this.cardSelectRef.setSwitchPanelItem(SWITCH_PANEL_ITEM.ALL);
    //     }, 200);
    // }
  }

  public onDraftCollectionClicked(collection: ICardCollection): void {
    console.log(collection)
    // this.userDataService.librarySavedScrollPosition = this.libraryPageViewRef.nativeElement.scrollTop;
    this.router.navigate(['/library'], {
      relativeTo: this.route,
      queryParams: { collectionId: collection.collectionId },
      queryParamsHandling: 'merge'
    });
  }

  public onModelCardPressed() {
    this.currentLibraryFooterMode = FOOTER_MODE.CARD_FULLSCREEN;
  }

  public onModelCardClicked(card: ModelCardRendered) {
    this.currentLibraryFooterMode = this.currentLibraryFooterMode === FOOTER_MODE.DEFAULT || this.currentLibraryFooterMode === FOOTER_MODE.SHOW_BUY_BTN ? FOOTER_MODE.CARD_FULLSCREEN : FOOTER_MODE.DEFAULT;
  }


  public onWebLibraryNavbarBtnClicked(navbarBtn: NAVBAR_BTN): void {
    console.log(navbarBtn)
    switch (true) {
      case navbarBtn === NAVBAR_BTN.BACK && this.webNavService.footerMode === FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW:
        this.webNavService.footerMode = FOOTER_MODE.LIBRARY;
        this.weblibraryMode = LIBARY_MODE.DEFAULT;

        break;
      case navbarBtn === NAVBAR_BTN.HOME:
      this.router.navigateByUrl('/home');
      break;
      case navbarBtn === NAVBAR_BTN.SEARCH:
        this.utilityService.showImportantSnackBar('Coming soon', 250, 750);
        // this.router.navigateByUrl('/uploadsandbox');
        break;
      case navbarBtn === NAVBAR_BTN.BACK_PROFILE:
        this.webNavService.footerMode = FOOTER_MODE.LIBRARY;
      break;
    }
  }

  public onCardControlBtnClicked(action: ICardAction): void {
    this.utilityService.triggerHaptics();
    this.webLibraryFeedModificator = undefined;
    this.cardToEdit = action.card;
    console.log('card clicked', action);

    switch (action.controlAction) {
      case CARD_CONTROL_BTN.CONTROL_ACTIONS_DEFAULT:
        this.utilityService.notImplemented();

        break;
      case CARD_CONTROL_BTN.UNLIKE_DRAFT_CARD:
      case CARD_CONTROL_BTN.LIKE_DRAFT_CARD:
        this.modelCardsFeedRef.dispatchCurrentCardLikeClick();
        break;
      case CARD_CONTROL_BTN.CANCEL_PUBLISH:
        this.openCancelPublishingCardConfirmationDialog(this.cardToEdit)
        break;
      case CARD_CONTROL_BTN.RENAME_CARD:
        // TO BE DONE !

        // this.nameDialogDefaultValue = this.cardToEdit.cardTitle;
        // this.lastNameInputValue = this.cardToEdit.cardTitle;
        // this.webNavService.footerMode = FOOTER_MODE.RENAME_ITEM;
        // this.isNameChangeDialogueShown = true;
        break;
      case CARD_CONTROL_BTN.DELETE_MODEL:
        // TO BE DONE !
        this.utilityService.notImplemented();

        break;
      case CARD_CONTROL_BTN.CONTROL_ACTION_REJECTED:
        this.utilityService.notImplemented();
        break;
      case CARD_CONTROL_BTN.PUBLISH_SINGLE_CARD:
        console.log('PUBLISH SINGLE CARD', action);
        const selectedCardsToPublish = this.userDataService.uploadedCards.find(card => card.id === action.card.id);
        this.userDataService.sendModelForReview(selectedCardsToPublish);
        this.webNavService.footerMode = FOOTER_MODE.PORTFOLIO;
        this.isModelFeedShown = false;

        break;
      case CARD_CONTROL_BTN.BUY_MODEL:
        this.utilityService.notImplemented();
        break;
      case CARD_CONTROL_BTN.CONTROL_BACK_BTN:
        this.utilityService.triggerHaptics();
        console.log('Portfolio, onModelFeedBackBtnClick: ');

        switch (true) {
          case this.weblibraryMode === LIBARY_MODE.PUBLISH_REJECTED_REVIEW:
            this.webNavService.footerMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
            this.isModelFeedShown = false;
            break;
          default:
            // this.onPortfolioSwitchPanelClick(this.currentPortfolioSwitchPanelMode);
            this.isModelFeedShown = false;
            this.webNavService.footerMode = FOOTER_MODE.LIBRARY;
            break;
        }
        break;
      default:
        break;
    }
  }

  public openCancelPublishingCardConfirmationDialog(card: ICard) {
    const dialogConfig: MatDialogConfig<DialogData> = {

      data: {
        dialogTitle: undefined,
        dialogText: `Are you sure you want to cancel publishing model "${card.cardTitle}" ? `,
        confirmBtntext: 'Yes',
        declineBtnText: 'No'
      }
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.cardService.cancelReviewModelPostRequest(card).then((resp) => {
          this.utilityService.showSnackBar('Review cancelled succesfully.', 500);
        }).catch(error => this.utilityService.showErrorSnackBar(`Error occured on cancelling review: ${JSON.stringify(error.error)}`, 500));

        this.userDataService.cancelModelPublishing(this.cardToEdit);
        this.webNavService.footerMode = FOOTER_MODE.LIBRARY_PUBLISH_REJECTED_REVIEW;
        this.isModelFeedShown = false;

      }
    });
  }

  public ngOnDestroy(): void {
    this.uploadStatusSub.unsubscribe();
    this.initilizeDataSub.unsubscribe();
  }


}
